
import { defineComponent, computed, reactive, onMounted, toRefs } from "vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import { useI18n } from "vue-i18n";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import ListItemEditInfo from "@/components/atomics/list-item/ListItemEditInfo.vue";
import UpdateAddEmailPopup from "@/components/popups/UpdateAddEmailPopup.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import { PartnerRepository, RepositoryFactory } from "@/lib/https";
import { useRoute, useRouter } from "vue-router";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import FlatButton from "@/components/atomics/button/FlatButton.vue";

export default defineComponent({
  name: "Partner",
  components: {
    AfterLoginPage,
    BreadScrum,
    ListItemEditInfo,
    UpdateAddEmailPopup,
    FlatButton,
  },
  setup() {
    const { t } = useI18n();
    const { popupState, openPopup } = usePopupControl();
    const { openNotification } = useNotificationControl();

    const route = useRoute();
    const router = useRouter();
    const partnerCompanyID = route.params.partnerID;

    const state = reactive({
      partnerData: {
        partnerId: "",
        name: "",
        emails: [] as any,
        canUpdate: "",
      },
      emailToEdit: {
        name: "",
        initialTextValue: [] as any,
        title: "",
        label: "",
        placeholder: "",
        action: async (emails: any) => {
          const emailsJson = JSON.stringify({
            emails: emails,
          });
          await updatePartnerEmail(emailsJson, partnerCompanyID);
        },
      },
      databreadScrumLinks: [] as { text: string; to: string }[],
      currentPartner: "",
    });

    const infoRows = computed(() => [
      {
        title: t("partner.titleEmailAddress"),
        value: state.partnerData.emails,
        canUpdate: state.partnerData.canUpdate,
        action: () => {
          state.emailToEdit.name = "email";
          state.emailToEdit.initialTextValue = state.partnerData.emails;
          state.emailToEdit.title = t("partner.editEmailAddress");
          state.emailToEdit.label = t("partner.editEmailAddressLabel");
          state.emailToEdit.placeholder = t("partner.titleEmailAddress");
          openPopup("updateText");
        },
      },
    ]);

    const paymentNotificationRows = computed(() => [
      {
        title: t("partner.paymentNotificationTitle"),
        value: "",
        action: () => {
          router.push({
            name: state.currentPartner,
            params: {
              partnerName: state.partnerData.name,
            },
            query: {
              partnerID: partnerCompanyID,
            },
          });
        },
      },
    ]);

    const { getPartnerEmail, updatePartnerEmail } =
      RepositoryFactory.getRepository<PartnerRepository>(PartnerRepository);

    const getPartnerInfo = async () => {
      const partnerDetail = await getPartnerEmail(partnerCompanyID as string);
      state.partnerData = partnerDetail;

      if (state.partnerData.name == "株式会社C-mind") {
        state.currentPartner = "cMind";
      } else if (state.partnerData.name == "株式会社Wiz") {
        state.currentPartner = "Wiz";
      } else {
        state.currentPartner = "";
      }

      state.databreadScrumLinks = [
        {
          text: t("partner.ListPartner"),
          to: "/partner",
        },
        {
          text: state.partnerData.name,
          to: "/partner/" + partnerCompanyID,
        },
      ];
    };

    onMounted(getPartnerInfo);

    return {
      ...toRefs(state),
      infoRows,
      popupState,
      getPartnerInfo,
      openNotification,
      paymentNotificationRows,
    };
  },
});
