
import { defineComponent, PropType, reactive } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { useI18n } from "vue-i18n";

type Action = (emails: any[]) => void;

export default defineComponent({
  name: "UpdateAddEmailPopup",
  components: {
    Popup,
    FlatButton,
    ValidationTextField,
    TextButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    initialTextValue: {
      type: Array,
      default: () => [],
    },
    btnText: {
      type: String,
      default: "変更する",
    },
    action: {
      type: Function as PropType<Action>,
      required: true,
    },
  },
  emits: ["onUpdateTextDone"],
  setup(props, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateText";
    const { t } = useI18n();

    const state = reactive({
      emails: Array.from(props.initialTextValue),
    });

    const updateText = async () => {
      if (!state.emails.includes("")) {
        await props.action(state.emails);
        emit("onUpdateTextDone");
        closePopup(popupKey);
      }
    };

    return {
      state,
      popupKey,
      updateText,
    };
  },
  methods: {
    addCustomField(): void {
      this.state.emails.push("");
    },
    removeCustomField(index: number): void {
      if (this.state.emails.length > 1) {
        this.state.emails.splice(index, 1);
      }
    },
  },
});
