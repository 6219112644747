import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-adafce84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "popup-input-label" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        "on-click": _ctx.updateText,
        text: _ctx.btnText,
        class: "w-full",
        enabled: _ctx.state.emails.includes('') ? false : true
      }, null, 8, ["on-click", "text", "enabled"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createVNode(_component_TextButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addCustomField())),
          icon: require('icons/add.svg'),
          text: _ctx.$t('partner.addField')
        }, null, 8, ["icon", "text"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.emails, (email, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "mt-2"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ValidationTextField, {
              value: _ctx.state.emails[index],
              "onUpdate:value": ($event: any) => ((_ctx.state.emails[index]) = $event),
              placeholder: _ctx.placeholder,
              type: 'email',
              message: {
            error_required: _ctx.$t('errors.required'),
            error_format_email: _ctx.$t('errors.formatEmail'),
          },
              rules: "required|email"
            }, null, 8, ["value", "onUpdate:value", "placeholder", "message"]),
            _createElementVNode("img", {
              class: "icon-del ml-1",
              src: require('icons/close-red-circle.svg'),
              onClick: ($event: any) => (_ctx.removeCustomField(index))
            }, null, 8, _hoisted_4)
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}