import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08fa1aae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-body" }
const _hoisted_2 = { class: "section-header big" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  class: "section-header big mt-5!",
  id: "memo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_ListItemEditInfo = _resolveComponent("ListItemEditInfo")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_UpdateAddEmailPopup = _resolveComponent("UpdateAddEmailPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, null, {
      "top-part": _withCtx(() => [
        _createVNode(_component_BreadScrum, { paths: _ctx.databreadScrumLinks }, null, 8, ["paths"])
      ]),
      "left-bottom-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("partner.titlePartnerInfo")), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoRows, (info, index) => {
            return (_openBlock(), _createBlock(_component_ListItemEditInfo, {
              key: index,
              "info-title": info.title,
              "info-value": info.value,
              "disable-action": !info.canUpdate,
              "has-divider": "",
              onOnEdit: info.action
            }, {
              value: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(info.value, (email, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(email), 1))
                }), 128))
              ]),
              _: 2
            }, 1032, ["info-title", "info-value", "disable-action", "onOnEdit"]))
          }), 128)),
          (_ctx.currentPartner)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("partner.paymentNotification")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentNotificationRows, (info, index) => {
                  return (_openBlock(), _createBlock(_component_ListItemEditInfo, {
                    key: index,
                    "info-title": info.title,
                    "info-value": info.value,
                    "has-divider": ""
                  }, {
                    tail: _withCtx(() => [
                      _createVNode(_component_FlatButton, {
                        text: _ctx.$t('common.confirm'),
                        class: "confirm-btn",
                        onClick: info.action
                      }, null, 8, ["text", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["info-title", "info-value"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    (_ctx.popupState.updateText)
      ? (_openBlock(), _createBlock(_component_UpdateAddEmailPopup, _mergeProps({ key: 0 }, _ctx.emailToEdit, {
          onOnUpdateTextDone: _cache[0] || (_cache[0] = ($event: any) => {
      _ctx.getPartnerInfo();
      _ctx.openNotification();
    })
        }), null, 16))
      : _createCommentVNode("", true)
  ], 64))
}